import { AuthContext } from 'context/auth'
import { useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

const AppRoutes = ({
  component: Component,
  path,
  exact,
  isPrivate,
  ...rest
}) => {
  const {
    logout,
    authState: { token },
  } = useContext(AuthContext)

  useEffect(() => {
    if (isPrivate && !token) logout()
  }, [isPrivate, token, logout])

  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        isPrivate && !token ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  )
}

export default AppRoutes
