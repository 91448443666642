import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './theme/theme'
import { AuthProvider } from 'context/auth'
import { ModelProvider } from 'context/model'
import store from 'store'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
    },
  },
})

ReactDOM.render(
  <StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ModelProvider>
          <Provider store={store}>
            <ChakraProvider resetCSS theme={theme}>
              <Router>
                <App />
              </Router>
            </ChakraProvider>
          </Provider>
        </ModelProvider>
      </QueryClientProvider>
    </AuthProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
