import { createSlice } from '@reduxjs/toolkit'

export const approveSlice = createSlice({
  name: 'approve',
  initialState: {
    isApprovePopupOpen: false,
    isApproveRejected: false,
    isApprovePending: false,
    count: 5,
  },
  reducers: {
    openApprovePopup: state => {
      state.isApprovePopupOpen = true
    },
    closeApprovePopup: state => {
      state.isApprovePopupOpen = false
    },
    toggleApprovePopup: state => {
      state.isApprovePopupOpen = !state.isApprovePopupOpen
    },
    setApproveReject: (state, { payload }) => {
      state.isApproveRejected = payload
    },
    setApprovePending: (state, { payload }) => {
      state.isApprovePending = payload
    },
    setCount: (state, { payload }) => {
      state.count = payload
    },
  },
})

export const {
  openApprovePopup,
  closeApprovePopup,
  toggleApprovePopup,
  setApproveReject,
  setApprovePending,
  setCount,
} = approveSlice.actions
