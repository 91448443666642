import { createSlice } from '@reduxjs/toolkit'

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    isFeedbackPopupOpen: false,
    feedback: '',
    message: '',
    feedbackPosition: [0, 0, 0],
    isHotspotActive: false,
    hotspotPosition: null,
    hotspotNormal: null,
    activeFeedback: null,
  },
  reducers: {
    toggleHotspot: state => {
      state.isHotspotActive = !state.isHotspotActive
    },
    setHotspotPosition: (state, { payload }) => {
      state.hotspotPosition = payload.position
      state.hotspotNormal = payload.normal
    },
    clearHotspotPosition: state => {
      state.hotspotPosition = null
      state.hotspotNormal = null
    },
    openFeedbackPopup: state => {
      state.isFeedbackPopupOpen = true
    },
    closeFeedbackPopup: state => {
      state.isFeedbackPopupOpen = false
    },
    toggleFeedbackPopup: state => {
      state.isFeedbackPopupOpen = !state.isFeedbackPopupOpen
    },
    changeFeedback: (state, { payload }) => {
      state.feedback = payload
    },
    changeMessage: (state, { payload }) => {
      state.message = payload
    },
    setFeedbackPosition: (state, { payload }) => {
      state.feedbackPosition = payload
      state.isFeedbackPopupOpen = true
    },
    setActiveFeedback: (state, { payload }) => {
      state.activeFeedback = payload
    },
  },
})

export const {
  toggleHotspot,
  setHotspotPosition,
  clearHotspotPosition,
  openFeedbackPopup,
  closeFeedbackPopup,
  toggleFeedbackPopup,
  changeFeedback,
  changeMessage,
  setFeedbackPosition,
  setActiveFeedback,
} = feedbackSlice.actions
