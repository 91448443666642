import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
import styles from './global'

const theme = extendTheme({
  styles,
  components: {
    Heading: {
      baseStyle: {
        lineHeight: 1.175,
      },
    },
  },
  colors,
  fonts: {
    body: 'canada-type-gibson, system-ui, sans-serif',
    heading: 'canada-type-gibson, system-ui, sans-serif',
  },
  fontWeights: {
    medium: 600,
  },
  shadows: {
    primary: '0 3px 6px rgba(0, 0, 0, 0.16)',
    outline: 'none',
  },
})

export default theme
