const { createContext, useState } = require('react')

const ModelContext = createContext()
const { Provider } = ModelContext

const ModelProvider = ({ children }) => {
  const [modelContext, setModelContext] = useState(null)
  const [material, setMaterial] = useState(null)

  return (
    <Provider value={{ modelContext, setModelContext, material, setMaterial }}>
      {children}
    </Provider>
  )
}

export { ModelContext, ModelProvider }
