import {
  Flex,
  Image,
  Box,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { FaBars } from 'react-icons/fa'

import { SidebarContent } from './SidebarContent'
import { SidebarDrawer } from './SidebarDrawer'

export const Sidebar = () => {
  const isMobile = useBreakpointValue([true, true, false])
  const { isOpen, onClose, onOpen } = useDisclosure()
  return isMobile ? (
    <>
      <IconButton
        pos='absolute'
        zIndex='popover'
        top={0}
        left={0}
        bg='primary.800'
        _hover={{ bg: 'primary.600' }}
        _active={{ bg: 'primary.700' }}
        color='white'
        borderRadius={0}
        variant='ghost'
        icon={<FaBars />}
        onClick={onOpen}
      />
      <SidebarDrawer isOpen={isOpen} onClose={onClose}>
        <SidebarContent onClose={onClose} />
      </SidebarDrawer>
    </>
  ) : (
    <Flex
      direction='column'
      w='230px'
      bg='black'
      overflowY='auto'
      color='white'
    >
      <Box p={4}>
        <Image src={'/logo-full-white.svg'} alt='Logo' w='100%' />
      </Box>
      <SidebarContent />
    </Flex>
  )
}
