import { useToast } from '@chakra-ui/toast'
import { AuthContext } from 'context/auth'
import { useContext } from 'react'
import { useHistory } from 'react-router'

export default function useFetchError() {
  const { logout } = useContext(AuthContext)
  const history = useHistory()
  const toast = useToast()

  const handleFetchError = ({ error, title, description }) => {
    console.error(`error`, error)
    console.error(`error.response`, error?.response)

    if (error?.response?.status === 401) {
      toast({
        isClosable: true,
        status: 'error',
        title: 'Session expired',
        description: 'Your session has been expired. You must login again!',
      })
      logout()
      return history.push('/login')
    }

    if (error !== 'Missing queryFn') {
      toast({
        isClosable: true,
        status: 'error',
        title: title || 'Error',
        description:
          description ||
          'There is an unexpected request error. Please try again.',
      })
    }
  }

  return handleFetchError
}
