const { createContext, useState } = require('react')

const AuthContext = createContext()
const { Provider } = AuthContext

const initialState = {
  token: JSON.parse(localStorage.getItem('token')) || null,
  user: JSON.parse(localStorage.getItem('user')) || null,
}

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialState)

  const login = ({ token, user }) => {
    const userData = {
      id: user.id,
      email: user.email,
      username: user.username,
      fullname: user.fullname || null,
      company: user.company || null,
      companyEmail: user.companyEmail || null,
      phone: user.phone || null,
    }

    setAuthState({ token, user: userData })
    localStorage.setItem('user', JSON.stringify(userData))
    localStorage.setItem('token', JSON.stringify(token))
  }

  const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setAuthState(initialState)
  }

  return <Provider value={{ authState, login, logout }}>{children}</Provider>
}

export { AuthContext, AuthProvider }
