import { createSlice } from '@reduxjs/toolkit'

export const qrSlice = createSlice({
  name: 'qr',
  initialState: {
    isQRPopupOpen: false,
  },
  reducers: {
    openQRPopup: state => {
      state.isQRPopupOpen = true
    },
    closeQRPopup: state => {
      state.isQRPopupOpen = false
    },
    toggleQRPopup: state => {
      state.isQRPopupOpen = !state.isQRPopupOpen
    },
  },
})

export const { openQRPopup, closeQRPopup, toggleQRPopup } = qrSlice.actions
