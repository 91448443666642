import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useQueryErrorResetBoundary } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { IoIosRefresh } from 'react-icons/io'

import { Sidebar } from '../Sidebar'
import { useContext } from 'react'
import { AuthContext } from 'context/auth'
import useFetchError from 'hooks/useFetchError'

export const Layout = ({ children }) => {
  const {
    authState: { user },
  } = useContext(AuthContext)
  const history = useHistory()
  const { reset } = useQueryErrorResetBoundary()
  const handleFetchError = useFetchError()

  if (!user) {
    history.push('/login')
    return null
  }

  return (
    <Flex pos='relative' overflowX='hidden' h='100vh'>
      <Sidebar />
      <ErrorBoundary
        onError={error => {
          reset()
          handleFetchError({ error })
        }}
        onReset={reset}
        fallbackRender={({ resetErrorBoundary }) => (
          <VStack justify='center' align='center' w='full'>
            <Alert w='auto' status='error' borderRadius='lg'>
              <VStack px={8} py={4}>
                <AlertIcon boxSize={12} mb={4} />
                <VStack>
                  <Text mb={4}>There was an error while fetching data!</Text>
                  <Button
                    leftIcon={<IoIosRefresh />}
                    colorScheme='primary'
                    onClick={() => resetErrorBoundary()}
                  >
                    Try again
                  </Button>
                </VStack>
              </VStack>
            </Alert>
          </VStack>
        )}
      >
        <Box h='full' flex='1' overflowY='auto'>
          {children}
        </Box>
      </ErrorBoundary>
    </Flex>
  )
}
