import { Suspense, useEffect } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { Box, Flex } from '@chakra-ui/react'
import { ReactQueryDevtools } from 'react-query/devtools'

import { Loader } from './components/Loader'
import { Layout } from './components/Layout'

import routes from './routes/routes.js'
import AppRoute from './routes/AppRoute'

function App() {
  const { pathname } = useLocation()
  const protectedRoutes = routes.filter(r => r.isPrivate).map(r => r.path)
  const Wrapper = protectedRoutes.includes(pathname) ? Layout : Box

  useEffect(() => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = '3ed12d58-dfbf-4b6e-bf55-a8b14d93a0fe'
    ;(function () {
      var d = document
      var s = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }, [])

  return (
    <Wrapper minH='100vh'>
      <Suspense
        fallback={
          <Flex h='100vh' justify='center' align='center'>
            <Loader />
          </Flex>
        }
      >
        <Switch>
          {routes.map(({ path, isPrivate, component, exact }) => (
            <AppRoute
              exact={exact}
              key={path}
              path={path}
              component={component}
              isPrivate={isPrivate}
            />
          ))}
        </Switch>
      </Suspense>
      <ReactQueryDevtools initialIsOpen />
    </Wrapper>
  )
}

export default App
