import { createSlice } from '@reduxjs/toolkit'

export const modelSlice = createSlice({
  name: 'model',
  initialState: {
    showFeedbacks: false,
    showDimensions: false,
    showThumbnails: true,
    stats: null,
    camera: null,
    isFullscreen: true,
    exposure: {},
    metalness: {},
    roughness: {},
    counts: [],
    textures: [],
    environment: {},
    showSkyboxImage: false,
  },
  reducers: {
    toggleFeedbacks: state => {
      state.showFeedbacks = !state.showFeedbacks
    },
    toggleDimensions: state => {
      state.showDimensions = !state.showDimensions
    },
    toggleThumbnails: state => {
      state.showThumbnails = !state.showThumbnails
    },
    loadStats: (state, { payload }) => {
      state.stats = payload
    },

    setStats: (state, { payload }) => {
      state.stats = { ...state.stats, ...payload }
    },
    setCamera: (state, { payload }) => {
      state.camera = payload
    },
    toggleFullScreenMode: state => {
      state.isFullscreen = !state.isFullscreen
    },
    setExposure: (state, { payload: { id, exposure } }) => {
      state.exposure[id] = exposure
    },
    setMetalness: (state, { payload: { id, metalness } }) => {
      state.metalness[id] = metalness
    },
    setRoughness: (state, { payload: { id, roughness } }) => {
      state.roughness[id] = roughness
    },
    loadProperties: (state, { payload }) => {
      state.roughness[payload.id] = 1
      state.metalness[payload.id] = 1
      state.exposure[payload.id] = payload.exposure
    },
    resetProperties: (state, { payload: { id, environment, exposure } }) => {
      state.roughness[id] = 1
      state.metalness[id] = 1
      state.exposure[id] = exposure
      state.environment[id] = environment
    },
    setCounts: (state, { payload }) => {
      state.counts = payload
    },
    loadTextures: (state, { payload }) => {
      state.textures = payload
    },
    setEnvironment: (state, { payload: { id, environment } }) => {
      state.environment[id] = environment
    },
    toggleSkyboxImage: state => {
      state.showSkyboxImage = !state.showSkyboxImage
    },
  },
})

export const {
  toggleDimensions,
  toggleFeedbacks,
  toggleThumbnails,
  setStats,
  setCamera,
  toggleFullScreenMode,
  setExposure,
  setMetalness,
  setRoughness,
  loadProperties,
  resetProperties,
  setCounts,
  loadTextures,
  setEnvironment,
  toggleSkyboxImage,
} = modelSlice.actions

export default modelSlice.reducer
