import { Box, Spinner } from '@chakra-ui/react'

export const Loader = () => {
  return (
    <Box h='full' w='full' pos='relative' justify='center' align='center'>
      <Spinner
        pos='absolute'
        left='50%'
        top='50%'
        translate='transform(-50%, -50%)'
        thickness={8}
        size='xl'
        speed='0.5s'
        color='primary.500'
      />
    </Box>
  )
}
