export const colors = {
  primary: {
    50: '#e4e5f9',
    100: '#babeef',
    200: '#8d93e4',
    300: '#5f68d9',
    400: '#3c47d1',
    500: '#1a27c9',
    600: '#1721ad',
    700: '#111999',
    800: '#0b1188',
    900: '#0f126b',
  },
  secondary: {
    50: '#fefbec',
    100: '#fffadb',
    200: '#fff6c3',
    300: '#fff4b6',
    400: '#fbeb8c',
    500: '#f7e05f',
    600: '#e7cc41',
    700: '#c6ac31',
    800: '#a38c25',
    900: '#836e1a',
  },
  dark: {
    50: '#666666',
    100: '#555555',
    200: '#444444',
    300: '#333333',
    400: '#222222',
    500: '#222222',
    600: '#111111',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  muted: '#6E6E6E',
  light: '#DCDCDE',
}
