import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

export const SidebarDrawer = ({ isOpen, onClose, children }) => {
  return (
    <Drawer placement='left' isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bg='black' color='white' p={0}>
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody m={0} p={0}>
            {children}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
