import { createSlice } from '@reduxjs/toolkit'

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    pageIndex: 0,
    globalFilter: undefined,
  },
  reducers: {
    setPageIndex: (state, { payload }) => {
      state.pageIndex = payload
    },
    setFilter: (state, { payload }) => {
      state.globalFilter = payload
    },
  },
})

export const { setPageIndex, setFilter } = tableSlice.actions
