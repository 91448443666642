import { lazy } from 'react'

const Home = lazy(() => import('../views/Home'))
const Auth = lazy(() => import('../views/Auth'))
const Orders = lazy(() => import('../views/Orders'))
const Notifications = lazy(() => import('../views/Notifications'))
const Settings = lazy(() => import('../views/Settings'))
const Models = lazy(() => import('../views/Models'))
const EditModel = lazy(() => import('../views/EditModel'))
const Confirmed = lazy(() => import('../views/Confirmed'))
const NotFound = lazy(() => import('../views/NotFound'))
const LoginRedirect = lazy(() => import('../views/LoginRedirect'))
const ResetPassword = lazy(() => import('../views/ResetPassword'))

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    isPrivate: true,
  },
  {
    path: '/login',
    component: Auth,
  },
  {
    path: '/confirmed',
    component: Confirmed,
  },
  {
    path: '/model/:id',
    component: EditModel,
    isPrivate: true,
  },
  {
    path: '/models',
    component: Models,
    isPrivate: true,
  },
  {
    path: '/orders',
    component: Orders,
    isPrivate: true,
  },
  {
    path: '/notifications',
    component: Notifications,
    isPrivate: true,
  },
  {
    path: '/settings',
    component: Settings,
    isPrivate: true,
  },
  {
    path: '/auth/:provider/redirect',
    component: LoginRedirect,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/*',
    component: NotFound,
  },
]

export default routes
