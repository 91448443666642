import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { approveSlice } from './approve'
import { feedbackSlice } from './feedback'
import { modelSlice } from './model'
import { qrSlice } from './qr'
import { tableSlice } from './table'

const store = configureStore({
  middleware: [thunk],
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    model: modelSlice.reducer,
    feedback: feedbackSlice.reducer,
    approve: approveSlice.reducer,
    qr: qrSlice.reducer,
    table: tableSlice.reducer,
  },
})

export default store
