import {
  chakra,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { AuthContext } from 'context/auth'
import { Fragment, useContext } from 'react'
import { BsChatDots } from 'react-icons/bs'
import { FiBox, FiSettings, FiShoppingBag } from 'react-icons/fi'
import {
  IoIosHelpBuoy,
  IoIosLogOut,
  IoMdNotificationsOutline,
} from 'react-icons/io'
import { Link, useHistory } from 'react-router-dom'

const menuItems = [
  {
    text: 'Models',
    link: '/models',
    icon: FiBox,
    isActive: true,
  },
  {
    text: 'Orders',
    link: '/orders',
    icon: FiShoppingBag,
    isActive: false,
  },
  {
    text: 'Notifications',
    link: '/notifications',
    icon: IoMdNotificationsOutline,
    isActive: false,
  },
  {
    text: 'Settings',
    link: '/settings',
    icon: FiSettings,
    isActive: false,
  },
]

export const SidebarContent = ({ onClose }) => {
  const {
    logout,
    authState: { user },
  } = useContext(AuthContext)
  const history = useHistory()
  const logoutUser = () => {
    logout()
    history.push('/login')
  }

  return (
    <>
      <VStack
        align='start'
        spacing={1}
        p={4}
        bg='whiteAlpha.300'
        justify='center'
        h='120px'
      >
        <Text fontWeight='medium' fontSize='18px' textTransform='uppercase'>
          {user.company || '<Company Name>'}
        </Text>
        <Flex w='full' justify='space-between' fontSize='14px'>
          <Tooltip hasArrow label={user.id}>
            <Text color='gray.400'>
              ID #{user.id?.slice(0, 4)}...
              {user.id?.slice(user.id?.length - 4)}
            </Text>
          </Tooltip>
        </Flex>
        <Text fontSize='16px' color='gray.400'>
          {user.fullname || user.username}
        </Text>
      </VStack>

      {menuItems.map(item => (
        <Tooltip
          key={item.link}
          {...(item.isActive
            ? { as: Fragment }
            : {
                label:
                  "You don't have access to this page on feedback management system.",
                placement: 'right-end',
                hasArrow: true,
              })}
        >
          <HStack
            py={2}
            px={4}
            transition='margin 0.3s ease'
            {...(item.isActive
              ? {
                  as: Link,
                  to: item.link,
                  onClick: onClose,
                  _hover: { bg: 'primary.500', ml: 4 },
                  color: 'white',
                }
              : {
                  color: 'gray.500',
                })}
          >
            <Icon as={item.icon} />
            <Text>{item.text}</Text>
          </HStack>
        </Tooltip>
      ))}

      <Divider my={4} />

      <HStack
        as='a'
        href='mailto:hello@arspar.com'
        target='_blank'
        rel='noopener noreferrer'
        py={2}
        px={4}
        transition='margin 0.3s ease'
        _hover={{ bg: 'primary.500', ml: 4 }}
      >
        <Icon as={BsChatDots} />
        <Text>Chat with us</Text>
      </HStack>
      <HStack
        as='a'
        href='https://floorplanner.com/support'
        target='_blank'
        rel='noopener noreferrer'
        py={2}
        px={4}
        _hover={{ bg: 'primary.500', ml: 4 }}
        transition='margin 0.3s ease'
      >
        <Icon as={IoIosHelpBuoy} />
        <Text>Help center</Text>
      </HStack>

      <Divider my={4} />

      <HStack
        py={2}
        px={4}
        onClick={logoutUser}
        _hover={{ bg: 'primary.500', ml: 4 }}
        transition='margin 0.3s ease'
        as={chakra.button}
      >
        <Icon as={IoIosLogOut} />
        <Text>Logout</Text>
      </HStack>
    </>
  )
}
